import KatalLogger, { Level } from '@amzn/katal-logger';

import { isBotTraffic } from 'src/logging-helpers/botTraffic';

// We hare having fatal count issues in our logging
// Katal uses sendBeacon: https://stackoverflow.com/questions/28989640/navigator-sendbeacon-data-size-limits
export const BEACON_LOG_CHAR_SIZE = 65536;
export const LOG_MAX_SAFETY_PADDING = 0.9;
export const MAX_LOG_LINE_SIZE = Math.floor(BEACON_LOG_CHAR_SIZE * LOG_MAX_SAFETY_PADDING);

export const MAX_CONTEXT_SIZE = MAX_LOG_LINE_SIZE - 15000;
export const MAX_ERROR_STACK_SIZE = 2000;

const katalLoggerConfig = {
  url: '/trim/log',
  logThreshold: Level.INFO,
  // https://sage.amazon.dev/posts/1371557?t=7
  maxLogLineSize: MAX_LOG_LINE_SIZE,
  logToConsole: false,
  decodeStackTrace: true,
};

export const logger = new KatalLogger(katalLoggerConfig);

const truncateContext = (context?: Record<string, unknown>): Record<string, unknown> | undefined =>
  context && JSON.stringify(context).length > MAX_CONTEXT_SIZE
    ? {
        truncatedContext: JSON.stringify(context).substring(0, MAX_CONTEXT_SIZE),
      }
    : context;

const truncateError = (error: Error) => {
  if (error.stack && error.stack?.length > MAX_ERROR_STACK_SIZE) {
    // line was an es lint error because of parameter reassignment, but in this case
    // there are no setter methods so is necessary
    // eslint-disable-next-line
    error.stack = error.stack?.substring(0, MAX_ERROR_STACK_SIZE);
  }

  return error;
};

const toError = (error: any) => {
  if (error instanceof Error) {
    return truncateError(error);
  }
  // instead of throw null back we throw an error allowing us to at least track the metric
  if (error === null || error === undefined) return new Error('error was null or undefined');
  // JSON.stringify ignores Functions without throwing an error
  if (error instanceof Function) return new Error(String(error));

  try {
    // note: Symbols are also ignored by JSON.stringify
    return new Error(JSON.stringify(error));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(error));
  }
};

/**
 * Error logger with wrapper in case of logging failures
 */
export const logError = (source: string, message: string, error?: any, context?: Record<string, any>) => {
  if (!isBotTraffic) {
    try {
      if (error) {
        logger.error(message, toError(error), {
          source,
          ...truncateContext(context),
        });
      } else {
        logger.error(message, {
          source,
          ...truncateContext(context),
        });
      }
    } catch (err: any) {
      logger.error(`unable to log error from ${source}`, err);
    }
  }
};

/**
 * Sets up global error and unhandled promise rejection logging.
 * The callback function receives an error. The error will log only if the callback function returns true.
 */
logger.addErrorListener((error: any) => {
  if (error && error.stack) {
    logError('TrimGlobalListener', 'error caught at global Trim listener', error);
  }
  return false;
});
