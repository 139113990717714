import { NAV_ITEM_TYPE, NAV_MENU_IDS } from 'src/enums/navigation';
import { NavItem, NavItems, NRSNavItem } from 'src/interfaces/navigation';

const getItemType = (nrsNavItem: NRSNavItem): NAV_ITEM_TYPE => {
  const itemType = nrsNavItem.dataAttributes?.itemType;

  if (nrsNavItem.subNav) {
    return NAV_ITEM_TYPE.PARENT;
  }

  if (itemType === NAV_ITEM_TYPE.SUST_SITE_LINK) {
    return NAV_ITEM_TYPE.SUST_SITE_LINK;
  }

  if (itemType === NAV_ITEM_TYPE.LOGO) {
    return NAV_ITEM_TYPE.LOGO;
  }

  if (itemType === NAV_ITEM_TYPE.COPYRIGHT) {
    return NAV_ITEM_TYPE.COPYRIGHT;
  }

  if (itemType === NAV_ITEM_TYPE.EXTERNAL_LINK) {
    return NAV_ITEM_TYPE.EXTERNAL_LINK;
  }

  if (itemType === NAV_ITEM_TYPE.SEARCH) {
    return NAV_ITEM_TYPE.SEARCH;
  }

  if (itemType === NAV_ITEM_TYPE.HAMBURGER) {
    return NAV_ITEM_TYPE.HAMBURGER;
  }

  if (itemType === NAV_ITEM_TYPE.CLOSE) {
    return NAV_ITEM_TYPE.CLOSE;
  }

  if (itemType === NAV_ITEM_TYPE.SUB_NAV_TITLE) {
    return NAV_ITEM_TYPE.SUB_NAV_TITLE;
  }

  return NAV_ITEM_TYPE.LINK;
};

export const transformNRSNavItems = (nrsNavItems: NRSNavItem[]): NavItems =>
  nrsNavItems.map((nrsItem): NavItem => {
    const item: NavItem = {
      children: [],
      data: nrsItem.dataAttributes ?? {},
      id: nrsItem.menuId ?? '',
      itemType: getItemType(nrsItem),
      label: nrsItem.text ?? '',
      url: nrsItem.url ?? '',
    };

    if (nrsItem.subNav) {
      item.children = transformNRSNavItems(nrsItem.subNav);
    }

    return item;
  });

export const isHeader = (item: NRSNavItem) => item.menuId === NAV_MENU_IDS.HEADER;
export const isFooter = (item: NRSNavItem) => item.menuId === NAV_MENU_IDS.FOOTER;

export const isSearch = (item: NavItem) => item.itemType === NAV_ITEM_TYPE.SEARCH;
export const isSustSiteLink = (item: NavItem) => item.itemType === NAV_ITEM_TYPE.SUST_SITE_LINK;

export const isHamburger = (item: NavItem) => item.itemType === NAV_ITEM_TYPE.HAMBURGER;
